.trial {
    &-method {
        &__options {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 7rem;
            margin-bottom: 24rem;
        }

        &__option {
            border-radius: 12rem;
            border: 1rem solid #F2F4F6;
            width: 100%;
            height: 90rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            color: #000;
            text-align: center;
            font-family: Italian Plate No2 Expanded;
            font-size: 12rem;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            flex-direction: column;
            cursor: pointer;
        }

        &__checkmark {
            position: absolute;
            top: 8rem;
            right: 8rem;

            svg {
                width: 18rem;
                height: 18rem;
            }
        }

        &__cards {
            display: grid;
            grid-template-columns: repeat(4, 22rem);
            grid-gap: 6rem;
            margin-top: 8rem;
        }

        &__amount {
            color: #000;
            font-family: Italian Plate No2 Expanded;
            font-size: 48rem;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            display: flex;
            justify-content: center;
            

            span {
                font-size: 14rem;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;
            }
        }

        &__credit-card {
            // margin-top: 24rem;
            // margin-bottom: 24rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            &.hidden {
                visibility: hidden;
                position: absolute;
                opacity: 0;
                width: 0;
                height: 0;
                pointer-events: none;
            }
            iframe {
                width: 100%;
                overflow: hidden;
            }
        }
        &__footer {
            margin-top: 20rem;

        }

        &__rating {
            display: flex;
            justify-content: center;
            margin: 0 auto 20rem;
        }

        &__company-info {
            color: var(--Dark-Gray, #8F969C);
            text-align: center;
            font-family: Italian Plate No2 Expanded;
            font-size: 12rem;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            max-width: 224rem;
            margin: 0 auto;
        }
    }
    &-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            color: #8F969C;
            font-size: 12rem;
            font-weight: 500;
            line-height: 1.2;
            @media screen and (min-width: 500px) {
                font-size: 14rem;
            } 
        }
        strong {
            color: #8F969C;
            font-size: 12rem;
            font-weight: 400;
            line-height: 1.2;
            @media screen and (min-width: 500px) {
                font-size: 14rem;
            } 
        }
        .red {
            font-weight: 600;
            color: #EF2C4F;
        }
        margin-bottom: 10rem;
    }
    &-total {
        margin-top: 14rem;
        padding-top: 20rem;
        border-top: 1px solid #F2F4F6;
        margin-bottom: 24rem;
        &-line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
                color: #000;
                font-size: 16rem;
                font-weight: 600;
                line-height: 1.2;
                @media screen and (min-width: 500px) {
                    font-size: 18rem;
                } 
            }
            strong {
                color: #000;
                font-size: 16rem;
                font-weight: 600;
                line-height: 1.2;
                @media screen and (min-width: 500px) {
                    font-size: 18rem;
                } 
            }
            margin-bottom: 10rem;
        }
        &-bottom {
            color: #4DCC8C;
            font-size: 14rem;
                font-weight: 600;
                line-height: 1.2;
                text-align: right;
                @media screen and (min-width: 500px) {
                    font-size: 16rem;
                } 
        }

    }
    .block-button {
        position: fixed!important;
        width: 303rem;
        padding: 20rem 0rem 32rem!important;
        bottom: 0;
        background: linear-gradient(180deg, rgba(#FFF, 0) 0%, #FFF 20%);
          @media screen and (min-width: 500px) {
            width: 464rem;
            padding: 20rem 0rem 50rem!important;
                } 
    }
    overflow-y: auto;
    padding-right: 11rem;
    width: calc(100% + 11rem);
    margin-right: -11rem;
    // &::-webkit-scrollbar {
    //     width: 3rem;
    //     right: 3rem;
    //     position: absolute;
    //     top: 0;
    //   }
      
    // &::-webkit-scrollbar-track {
    //     background: transparent; 
    //   }
    // &::-webkit-scrollbar-thumb {
    //     background: var(--Gray, #DCDFE2);
    //     border-radius: 12rem; 
    // }
    input.error {
        &::placeholder {
            color: #EF2C4F!important;
        }
    }
}

#apple-pay-button-container {

    height: 62rem;
    width: 100%;
    min-width: 100%;
    border-radius: 100rem;
}
.apple-pay-button, #apple-pay {
    height: 62rem;
    width: 100%;
    min-width: 100%;
    border-radius: 100rem;
}
