@media screen and (min-width: 991px) {
    .block-home {
        overflow: hidden;
    }
    .home1 {
        position: relative;
        align-items: flex-start;
        // justify-content: flex-start;
        padding-bottom: 137rem;
        width: calc(100% );
        margin: 0;
        &::after {
            content: "";
            position: absolute;
            top: -50rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: calc(100% + 110rem);
            background: #5BF0A5;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI4NjQiIHZpZXdCb3g9IjAgMCAxNDQwIDg2NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjE0NDAiIGhlaWdodD0iODY0IiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfNzIzN180NzQwKSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzcyMzdfNDc0MCIgeDE9IjY4MSIgeTE9IjEyLjUiIHgyPSIzODYiIHkyPSI2NzMuNSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLW9wYWNpdHk9IjAuMjciLz4KPHN0b3Agb2Zmc2V0PSIxIi8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==);
            background-size: cover;
            z-index: -1;
        }

        &__logo {
            margin: 0 auto 108rem;
     
        }

        h1 {
            max-width: 621rem;
            text-align: left;
            font-family: "Italian Plate No2 Expanded", sans-serif;
            font-weight: 700;
            font-size: 72rem;
            line-height: 110%;
            color: #fff;
            margin-bottom: 45rem;
        }

        &-action {
            display: flex;
            align-items: center;
            margin-bottom: 80rem;

            .btn {
                width: 315rem;

            }

            img {
                width: auto;
                height: 54rem;
                margin-left: 39rem;
            }
        }

        .hero__hand {
            display: block;
            position: absolute;
            bottom: -390rem;
            right: -140rem;
            pointer-events: none;
            z-index: 0;
        }

        .hero__hand>img {
            width: 665rem;
            object-fit: cover;
        }

        .hero__hand>img:nth-child(2) {
            position: absolute;
            left: 210rem;
            top: 145rem;
            width: 246rem;
            border-radius: 20rem;
            z-index: -2;
        }

        .hero__hand-images {
            position: absolute;
            left: 210rem;
            top: 145rem;
            width: 246rem;
            border-radius: 20rem;
            z-index: -1;
            overflow: hidden;
            aspect-ratio: 1500/3248;
        }

        .hero__hand-images img {
            width: 100%;

            transform: translateX(100%) translateY(-50%);
            position: absolute;
            left: 0;
            top: 51%;

            transition: 0s ease-in;
        }

        .hero__hand-images img.active {

            transition: 0.3s ease-out;

            transform: translateX(0%) translateY(-50%);
        }

        .hero-featured p {
            font-family: "Italian Plate No2 Expanded", sans-serif;
            font-weight: 400;
            font-size: 14rem;
            line-height: 140%;
            color: #fff;
            margin-bottom: 20rem;
        }

        .hero-featured-wrapper {

            display: flex;

            justify-content: flex-start;
            padding: 0;
            width: 100%;
            flex-wrap: nowrap;
        }

        .hero-featured-wrapper img {
            margin: 0 !important;
            margin-right: 50rem !important;
            height: 26rem;
            width: auto;
        }
    }
}
@media screen and (min-width: 991px) {
    .home2 {
        position: relative;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: 137rem;
        width: calc(100% );
        margin: 0;
        &__logo {
            margin: 0 auto 108rem;
        }
        &-content {
            display: flex;
            align-items: center;
            flex: 1;
            &-wrapper {
                display: flex;
                align-items: flex-end;
            }
        }
        &-right {
            width: 389rem;
            padding-top: 412rem;
            margin-right: 44rem;
            position: relative;
            &-img {
                position: absolute;
                width: 673rem;
                bottom: 68rem;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        h1 {
            max-width: 621rem;
            text-align: left;
            font-family: "Italian Plate No2 Expanded", sans-serif;
            font-weight: 700;
            font-size: 72rem;
            line-height: 110%;
            color: #000;
            margin-bottom: 45rem;
            br {
                display: block;
            }
        }
        &-action {
            display: flex;
            align-items: center;
            margin-bottom: 80rem;
            img {
                width: auto;
                height: 54rem;
                margin-right: 39rem;
            }
        }
        &::after {
            content: "";
            position: absolute;
            top: -50rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: calc(100% + 110rem);
            background: #fff;
      
            z-index: -1;
        }
        .hero-featured p {
            font-family: "Italian Plate No2 Expanded", sans-serif;
            font-weight: 400;
            font-size: 14rem;
            line-height: 140%;
            color: #000;
            margin-bottom: 20rem;
            text-align: left;
        }

        .hero-featured-wrapper {

            display: flex;

            justify-content: flex-start;
            padding: 0;
            width: 100%;
            flex-wrap: nowrap;
        }

        .hero-featured-wrapper img {
            margin: 0 !important;
            margin-right: 50rem !important;
            height: 26rem;
            width: auto;
        }
    }
}

@media screen and (min-width: 991px) {
    .home3 {
        position: relative;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: 137rem;
        width: 100%;
        margin: 0;
        &__logo {
            margin: 0 auto 108rem;
        }
        &-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            width: 100%;
        }
        &-right {
            width: 414rem;
            
            margin-right: 74rem;
            position: relative;

        }
        h1 {
            max-width: 592rem;
            text-align: left;
            font-family: "Italian Plate No2 Expanded", sans-serif;
            font-weight: 700;
            font-size: 62rem;
            line-height: 110%;
            color: #000;
            margin-bottom: 45rem;
            br {
                display: block;
            }
        }
        p {
            font-size: 20rem;
            text-align: left;
            max-width: 500rem;
        }
        .btn {
            max-width: 389rem;
        }
        &-action {
            display: flex;
            align-items: center;
            margin-bottom: 80rem;
            img {
                width: auto;
                height: 54rem;
                margin-right: 39rem;
            }
        }
        &::after {
            content: "";
            position: absolute;
            top: -50rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: calc(100% + 110rem);
            background: #fff;
      
            z-index: -1;
        }
       
    }
}

@media screen and (min-width: 991px) {
    .page5 {
        position: relative;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: 137rem;
        &__logo {
            margin: 0 auto 108rem;
        }
        &-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            width: 100%;
            &:after {
                display: none;
            }
            &::before {
                display: none;
            }
        }
        &-right {
            width: 568rem;

            position: relative;
            &::after {
                position: absolute;
                content: '';
                width: 20rem;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(90deg, white, rgba(#FFFFFF, 0));
                z-index: 2;
            }
    
            &::before {
                position: absolute;
                content: '';
                width: 20rem;
                height: 100%;
                top: 0;
                right: 0;
                background: linear-gradient(-90deg, white, rgba(#FFFFFF, 0));
                z-index: 2;
            }
        }
        h1 {
            max-width: 621rem;
            text-align: left;
            font-family: "Italian Plate No2 Expanded", sans-serif;
            font-weight: 700;
            font-size: 72rem;
            line-height: 110%;
            color: #000;
            margin-bottom: 45rem;
            br {
                display: block;
            }
        }
        p {
            font-size: 20rem;
            text-align: left;
            max-width: 381rem;
            position: static;
            margin-bottom: 32rem;
        }
        .btn {
            max-width: 389rem;
        }
        &-action {
            display: flex;
            align-items: center;
            margin-bottom: 80rem;
            img {
                width: auto;
                height: 54rem;
                margin-right: 39rem;
            }
        }
        &::after {
            content: "";
            position: absolute;
            top: -50rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: calc(100% + 110rem);
            background: #fff;
      
            z-index: -1;
        }
       
    }
}

@media screen and (min-width: 991px) {
    .page6 {
        position: relative;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: 137rem;
        height: 100%;
        &__logo {
            margin: 0 auto 108rem;
        }
        &-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            width: 100%;
        }
        &-right {
            width: 390rem;
            position: relative;

        }
        h1 {
            max-width: 621rem;
            text-align: left;
            font-family: "Italian Plate No2 Expanded", sans-serif;
            font-weight: 700;
            font-size: 72rem;
            line-height: 110%;
            color: #000;
            margin-bottom: 45rem;
            br {
                display: block;
            }
        }
        p {
            font-size: 20rem;
            text-align: left;
            max-width: 450rem;
            position: static;
            margin-bottom: 32rem;
        }
        .btn {
            max-width: 389rem;
        }
        &-action {
            display: flex;
            align-items: center;
            margin-bottom: 55rem;
            flex-direction: column;
            img {
                width: auto;
                height: 102rem;
                margin-right: 0rem;
                margin-bottom: 40rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .hero-featured {
            p {
                font-weight: 500;
                font-size: 20rem;
                line-height: 100%;
                text-align: center;
                color: #000;
                bottom: 30rem;
            }
            &-wrapper {
                display: flex;
                justify-content: space-between;
                img {
                    width: auto;
                    height: 28rem;
                }
            }
        }
        &::after {
            content: "";
            position: absolute;
            top: -50rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: calc(100% + 110rem);
            background: #fff;
      
            z-index: -1;
        }
       
    }
}

@media screen and (min-width: 991px) {
    .page7 {
        position: relative;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: 137rem;
        height: 100%;
        &__logo {
            margin: 0 auto 108rem;
        }
        &-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            width: 100%;
        }
        &-right {
            width: 415rem;
            position: relative;

        }
        h1 {
            max-width: 621rem;
            text-align: left;
            font-family: "Italian Plate No2 Expanded", sans-serif;
            font-weight: 700;
            font-size: 72rem;
            line-height: 110%;
            color: #000;
            margin-bottom: 45rem;
            br {
                display: block;
            }
        }
        p {
            font-size: 20rem;
            text-align: left;
            max-width: 500rem;
            position: static;
            margin-bottom: 0rem;
            width: 100%;
        }
        .btn {
            max-width: 389rem;
        }
      

        &::after {
            content: "";
            position: absolute;
            top: -50rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: calc(100% + 110rem);
            background: #fff;
      
            z-index: -1;
        }

        &-item {
            transition: .3s;
            img {
                width: 142rem;
                height: 150rem;
                transition: .3s;
                transform-origin: bottom;
            }
            &-bottom {
                span {
                    font-size: 16rem;
                }
 
            }
            &:hover {
                background:  #52CC8E;
                img {
                    transform: scale(0.9);
                }
            }
        }
    }
}