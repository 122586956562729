.googlesingup-bottom {
    margin-top: 24rem;

    &-login {
        margin-bottom: 24rem;
        color: var(--Dark-Gray, #8F969C);
        text-align: center;
        font-family: Italian Plate No2 Expanded;
        font-size: 14rem;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;

        @media screen and (min-width: 500px) {
            font-size: 16rem;
        }

        a {
            color: #000;
            font-family: Italian Plate No2 Expanded;
            font-size: 14rem;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
            text-decoration-line: underline;

            @media screen and (min-width: 500px) {
                font-size: 16rem;
            }
        }
    }

    &-term {
        max-width: 216rem;
        margin: 0 auto;
        color: var(--Dark-Gray, #8F969C);
        text-align: center;
        font-family: Italian Plate No2 Expanded;
        font-size: 10rem;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;

        @media screen and (min-width: 500px) {
            font-size: 12rem;
        }

        span {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}