.plan-container {
    position: relative;
    height: 100%;

    &:after {
        position: absolute;
        width: 100%;
        content: '';
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 1.05%, #FFF 11.78%);
        height: 118rem;
        top: -36rem;
        left: 0;
        z-index: 1;
    }

    h1 {
        position: relative;
        z-index: 2;
    }
}

.plan {
    max-height: 600rem;
    position: relative;
    height: calc(var(--vh, 1vh) * 100 - 16rem - 64rem - 36rem - 32rem - 36rem);
    overflow: auto;
    padding-right: 11rem;
    width: calc(100% + 11rem);
    margin-right: -11rem;
    margin-bottom: -62rem;
    padding-bottom: 84rem;
    padding-top: 90rem;
    margin-top: -90rem;
    @media screen and (min-width: 500px)  {
        max-height: 100vh;
        height: calc(var(--vh, 1vh) * 100 - 64rem - 16rem - 50rem - 36rem - 32rem - 32rem);
    }
    &::-webkit-scrollbar {
        width: 3rem;
        right: 3rem;
        position: absolute;
        top: 0;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--Gray, #DCDFE2);
        border-radius: 12rem;
    }

    &-block {
        margin-bottom: 16rem;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 16rem;
        border-radius: 12rem;
        border: 1px solid #F2F4F6;
        overflow: hidden;

        &-left {
            max-width: 120rem;
            @media screen and (min-width: 500px) {
                max-width: 150rem;
            } 
            svg {
                width: 32rem;
                height: 32rem;
                margin-bottom: 10rem;
                display: block;
            }

            strong {
                font-weight: 700;
                font-size: 14rem;
                line-height: 1.2;
                color: #000000;
                margin-bottom: 4rem;
                display: block;
                @media screen and (min-width: 500px) {
                    font-size: 16rem;
                } 
            }

            p {
                font-weight: 400;
                font-size: 12rem;
                line-height: 1.2;
                color: #8F969C;
                @media screen and (min-width: 500px) {
                    font-size: 14rem;
                } 
            }
        }

        &-right {
            width: 150rem;
            aspect-ratio: 10/9;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-item {
        display: flex;
        align-items: center;

        svg {
            width: 32rem;
            height: 32rem;
            margin-right: 12rem;
            display: block;
        }

        color: var(--Black, #000);
        font-size: 14rem;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        @media screen and (min-width: 500px) {
            font-size: 16rem;
        } 

    }

    &-items {
        &-title {
            color: var(--Black, #000);
            font-size: 16rem;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            margin-bottom: 24rem;
            margin-top: 32rem;
            @media screen and (min-width: 500px) {
                font-size: 18rem;
            } 
        }

        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-column-gap: 9rem;
        grid-row-gap: 12rem;




    }

    &-bottom {
        position: relative;
        z-index: 1;
        padding-top: 20rem;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 33.77%);
        height: 82rem;
        margin-bottom: -62rem;
    }
}