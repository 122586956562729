.checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    // img {
    //     width: 44rem;
    //     min-width: 44rem;
    //     height: 44rem;
    //     border-radius: 50%;
    //     object-fit: cover;
    //     margin-right: 12rem;
    // }
    
    span {
        display: flex;
        align-items: center;
        > div {
            aspect-ratio: 101/110;
            margin-right: 12rem;
            width: 20rem;
            height: 20rem;

        }
    }
    color: #000;

    font-family: Italian Plate No2 Expanded, sans-serif;
    font-size: 14rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    @media screen and (min-width: 500px)  {
        font-size: 16rem;
    }
    &Icon {
        width: 18px;
        height: 18px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #CAD4DD;
        margin-left: auto;
        svg {
            width: 10px;
            height: 8px;
            opacity: 0;
            // transition: 0.25s;
        }
        &.error {
            border-color: #EF2C4F!important;
        }
    }
    input {
        width: 0;
        height: 0;
        visibility: hidden;
        pointer-events: none;
        // &:checked ~ .checkboxIcon {
        //     background: var(--black, #000);
        //     border-color: #000;
        //     svg {
        //         opacity: 1;
        //     }
        // }
    }
}