.bundle {
    // padding: 20rem;
    // border-radius: 12rem;
    // border: 2rem solid var(--Light-Gray, #F2F4F6);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 12rem;
    @media screen and (min-width: 500px) {
        margin-bottom: 60rem;
    } 
    &__header {
        color: #FFF;
        font-family: Italian Plate No2 Expanded;
        font-size: 12rem;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        border-radius: 100rem;
        background: var(--Blue, #2555FF);
        padding: 4rem 12rem;
        margin-bottom: 10rem;
        @media screen and (min-width: 500px) {
            font-size: 14rem;
        } 
    }

    &__title {
        color: #000;
        font-family: Italian Plate No2 Expanded;
        font-size: 20rem;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        margin-bottom: 16rem;
        @media screen and (min-width: 500px) {
            font-size: 22rem;
        } 
    }

    ul {
        // margin-bottom: 16rem;
        li {
            display: flex;
            align-items: center;
            margin-bottom: 30rem;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                background: #E9EEFF;
                width: 44rem;
                min-width: 44rem;
                height: 44rem;
                margin-right: 16rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                &:after {
                    background: url(../../../public/img/line.svg);
                    content: '';
                    width: 2rem;
                    height: calc(100% - 14rem);
                    background-position: top center;
                    background-size: 100% auto;
                    transform: translatex(-50%);
                    left: 50%;
                    top: 100%;
                    position: absolute;
                }
            }
            p {
                line-height: 1;
                span {
                    font-weight: 400;
                    font-size: 13rem;
                    color: #000;
                    line-height: 1.3;
                    display: block;
                    @media screen and (min-width: 500px) {
                        font-size: 15rem;
                    } 
                }
                strong {
                    font-weight: 700;
                    font-size: 16rem;
                    color: #000;
                    line-height: 1;
                    margin-bottom: 6rem;
                    display: block;
                    @media screen and (min-width: 500px) {
                        font-size: 18rem;
                    } 
                }
            }
            &.blue {
                i {
                    background: #2555FF;
                    &:after {
                        display: none;
                    }
                }
                p {
                    strong {
                        color: #2555FF;
                    }
                }
            }
        }
    }
    &__price {
        display: flex;
        color: #000;
        font-family: Italian Plate No2 Expanded;
        font-size: 16rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.1;
        align-items: center;
        margin-bottom: 24rem;
        @media screen and (min-width: 500px) {
            font-size: 18rem;
        } 
        strong {
            color: #8F969C;
            font-family: Italian Plate No2 Expanded;
            font-size: 16rem;
            font-style: normal;
            font-weight: 400;
            line-height: 110%;
            margin-left: auto;
            text-decoration: line-through;
            text-decoration-color: #EF2C4F;
            @media screen and (min-width: 500px) {
                font-size: 18rem;
            } 
        }

        span {
            color: #000;
            font-family: Italian Plate No2 Expanded;
            font-size: 16rem;
            font-style: normal;
            font-weight: 600;
            line-height: 110%;
            
            margin-left: 6rem;
            @media screen and (min-width: 500px) {
                font-size: 18rem;
            } 
        }
    }
    &__note {
        color: #000;
        font-family: Italian Plate No2 Expanded;
        font-size: 12rem;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        @media screen and (min-width: 500px) {
            font-size: 14rem;
        } 
    }
}