.personalcard {
    &-top {
        color: #000;
        text-overflow: ellipsis;
        font-family: "Italian Plate No2 Expanded";
        font-size: 7.761rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 2rem;
        padding-left: 4.6rem;
    }

    &__img {
        position: relative;
        width: 100%;
        margin-bottom: 5rem;

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            border-radius: 8.466rem;
            border: 0.706rem solid var(--Gray-light, #F2F4F6);
        }
    }
    &-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 4.6rem;
        overflow: hidden;
        color: #000;

        text-overflow: ellipsis;
        font-family: "Italian Plate No2 Expanded";
        font-size: 7.761rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        span{
            display: flex;
            align-items: center;
            font-weight: 600;
            svg {
                width: 8rem;
                height: 7rem;
                margin-left: 3rem;
            }
        }
    }
}