.btn {
    width: 100%;
    height: 62rem;
    min-height: 62rem;
    border-radius: 100rem;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--White, #FFF);
    text-align: center;
    font-family: Italian Plate No2 Expanded, sans-serif;
    font-size: 16rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    cursor: pointer;
    outline: none;
    border: none;
    transition: 0.3s;

    &.white {
        background: var(--white, #FFF);
        color: var(--black, #000);

        svg {
            path {
                fill: #000;
            }
        }
    }

    &.green {
        background: #5bf0a5;
        color: var(--black, #000);

        svg {
            path {
                fill: #000;
            }
        }
    }

    &:hover {
        //     transform: scale(0.95);
        //     opacity: 0.85;
    }

    &.pointerNone {
        pointer-events: none !important;
    }

    &.paypal-button {
        background: #FFC43A;
        color: #360906;
        transition: none;
    }

    svg {
        width: 21rem;
        height: 20rem;
        margin-left: 12rem;

        path {
            transition: 0.25s;
        }
    }

    &.disable {
        background: #F2F4F6;
        color: var(--Gray-dark, #8F969C);

        path {
            fill: #8F969C;
        }
    }

    &.error {
        background: #FDE5E9;
        color: #EF2C4F;
        transition: 0.25s;

        path {
            fill: #EF2C4F;
        }
    }

    &.animate {
        transition: none;
        // animation: scaleOpacityAnimationFirst 0.085s cubic-bezier(0.190, 1.000, 0.220, 1.000);
        // animation-direction: alternate;
    }

    &.opacity {
        margin-top: 32rem;
    }

    .spinner {
        width: 100%;
        text-align: center;
    }

    .spinner>div {
        width: 10rem;
        height: 10rem;
        background-color: #FFF;
        border-radius: 100%;
        display: inline-block;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        margin: 0 3rem;
    }

    .spinner .bounce1 {
        animation-delay: -0.32s;
    }

    .spinner .bounce2 {
        animation-delay: -0.16s;
    }
}



@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        transform: scale(0.6);
    }

    40% {
        transform: scale(1.0);
    }
}

@keyframes scaleOpacityAnimationFirst {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0.95);
        opacity: 0.85;
    }
}

@keyframes scaleOpacityAnimationSecond {
    0% {
        transform: scale(0.95);
        opacity: 0.85;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.btn[disabled] {
    pointer-events: none;
    background: #EFEFEF;
    color: rgba($color: #000000, $alpha: 0.6);
}

// @media screen and (min-width: 500rem)  {
//     .btn {
//         height: 70rem;
//         font-size: 20rem;
//         line-height: 20rem;
//         min-height: 70rem;
//         svg {
//             width: 34rem;
//             height: 34rem;
//             margin-left: 10rem;
//         }
//     }
// }