.connection {
    &-loader {
        &-wrapper {
            display: flex;
            flex-direction: column;
            // margin-bottom: 20rem;
        }

        &-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12rem;
            align-items: center;

            strong {
                color: var(--Black, #000);
                text-align: center;
                font-family: "Italian Plate No2 Expanded";
                font-size: 14rem;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
            }

            span {
                color: var(--Black, #000);
                text-align: center;
                font-family: "Italian Plate No2 Expanded";
                font-size: 14rem;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }

        &-line {
            position: relative;

            strong {
                border-radius: 100rem;
                background: #E9EEFF;
                height: 6rem;
                display: block;
            }

            span {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-radius: 100rem;
                background: var(--Blue, #2555FF);
            }
        }
        &-bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20rem;
            p {
                color: #8F969C;
                font-weight: 500;
                font-size: 12rem;
                line-height: 1.2;
                text-align: center;
            }
            // &-item {
            //     display: flex;
            //     align-items: center;
            //     svg {
            //         width: 18rem;
            //         height: 18rem;
            //         margin-right: 6rem;
            //     }
            //     span {
            //         color: var(--Gray-dark, #A7A8AA);
            //         text-align: center;
            //         font-family: "Italian Plate No2 Expanded";
            //         font-size: 12rem;
            //         font-style: normal;
            //         font-weight: 500;
            //         line-height: 140%;
            //     }
            // }
        }
    }

    &-slider {

        margin: 0 0rem 32rem;

        .swiper-slide {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .swiper-wrapper {
            align-items: center;
        }
    
    }
}