@import-normalize;

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Extralight.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Extralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Ultralight.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Ultralight.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Extrabold.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Extrabold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Bold.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Thin.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Demibold.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Demibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Black.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Light.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Regular.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Italian Plate No2 Expanded';
    src: url('../fonts/ItalianPlateNo2Expanded-Medium.woff2') format('woff2'),
        url('../fonts/ItalianPlateNo2Expanded-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

html {
    margin: 0;
}

html {
    height: 100%;
    font-size: calc(100vw / 375);
    scroll-behavior: smooth;
    background: #000;
}

body {
    height: 100%;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    font-family: 'Italian Plate No2 Expanded';
    font-size: 16rem;
    background: #000;

    -webkit-tap-highlight-color: transparent;

}

*,
*::before,
*::after {
    box-sizing: border-box;
}


body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

a {
    text-decoration: none;
}

ol,
ul {
    list-style: none;
}

img {
    max-width: 100%;
    display: block;
}

input,
button,
textarea,
select {
    font: inherit;
}

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
    vertical-align: middle;
}

textarea {
    resize: none;
}

section {
    position: relative;
}

input,
select,
button {
    outline: none;
}

.wrapper {
    display: flex;
    flex-direction: column;

    height: 100%;
}

.content {
    flex: 1 0 auto;
}

.container {
    margin: 0 auto;
    max-width: 520rem;
    width: 100%;
    height: 100%;
}

#root {
    height: 100%;
    position: relative;
    z-index: 1;
}



.header {
    padding: 11rem 20rem 3rem;

    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 16rem;

    &-top {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .back {
            cursor: pointer;
            left: 0rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            svg {
                width: 16rem;
                height: 16rem;
            }
        }

        .logo {
            width: 79rem;
            height: 22rem;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .line {
        align-items: center;
        margin-top: 23rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 3rem;

        &-item {
            width: 100%;
            height: 3rem;
            background: rgba(255, 255, 255, 0.20);
            border-radius: 5rem;

            i {
                background: #5BF0A5;
                border-radius: 5rem;
                height: 100%;
                display: block;
                max-width: 100%;
            }
        }


        svg {
            width: 100%;
            height: 3rem;
            border-radius: 5rem;
        }

        .rc-progress-line-trail {
            stroke: rgba(255, 255, 255, 0.20);
            border-radius: 5rem;
        }

        .rc-progress-line-path {
            stroke: #5BF0A5;
            border-radius: 5rem;
        }
    }
}

.wrapper {
    height: 100%;
    background: url(../../public/img/bg.webp);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &.block-white {
        background: #fff;
        .predict-wrapper {
            @media screen and (min-width: 500px) {
                margin-bottom: 60rem!important;
            } 
        }
        .mb-60 {
            @media screen and (min-width: 500px) {
                margin-bottom: 60rem!important;
            } 
        }

        .block {
            margin: 0;
            border-radius: 0;
            height: 100%;

        }

        .block-animate {
            padding: 0 36rem;
            height: 100% !important;

            >div {
                height: 100% !important;
            }
        }

        .block-height {
            height: 100%;

            >div {
                height: 100%;
            }
        }

    }

    &.block-welcome {
        overflow: auto;
        padding-bottom: 50rem;

        .block {
            // height: 100%;
            overflow: visible;

            >div {
                height: 100%;
            }
        }

        .welcome {
            overflow: visible;
            padding-bottom: 50rem;
            max-height: none;
        }

        .block-height>div {
            opacity: 1 !important;
        }
    }

    .block {
        margin: 0 16rem 16rem;
        border-radius: 28rem;
        background: var(--White, #FFF);

        display: flex;
        flex-direction: column;

        position: relative;
        padding: 36rem 0rem 32rem;
        overflow: hidden;
        max-height: 100%;

        >div {
            width: 100%;

        }

        &-height {
            width: 100%;
            display: flex;
            flex-direction: column;
            max-height: 100%;

            .css-mqftaq {
                max-height: 100%;
            }
        }

        &-animate {
            padding: 0 20rem;
            overflow: visible !important;
            max-height: 100%;

            >div {
                max-height: 100%;
                display: block !important;
            }
        }

        &-button {
            padding: 0 20rem;
            position: relative;
            z-index: 1;

            .forget {
                margin-top: 24rem;
                color: #000;
                text-align: center;
                font-size: 14rem;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;
                cursor: pointer;
            }
        }

        &-bottom {
            color: var(--Dark-Gray, #8F969C);
            text-align: center;
            font-family: Italian Plate No2 Expanded;
            font-size: 12rem;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;

            margin-top: 16rem;

            @media screen and (min-width: 500px) {
                font-size: 14rem;
            } 
            span {
                text-decoration: underline;
                cursor: pointer;
            }


        }
    }
}

h1 {
    color: #000;
    font-family: Italian Plate No2 Expanded, sans-serif;
    font-size: 26rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 12rem;
}

p.text {
    color: #000;
    font-family: Italian Plate No2 Expanded, sans-serif;
    font-size: 14rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    span {
        font-weight: 700;
    }

    strong {
        color: #2555FF;
        font-weight: 700;
    }
}

.input {
    position: relative;
    display: flex;
    align-items: center;

    input {
        border-radius: 11rem;
        border: 1rem solid var(--bg-gray, #F2F4F6);
        width: 100%;
        height: 49rem;
        padding-left: 16rem;
        color: var(--Black, #000);
        font-family: Italian Plate No2 Expanded;
        font-size: 14rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        padding-right: 16rem;
        @media screen and (min-width: 500px) {
            font-size: 16rem;
        }
        &::placeholder {
            color: #8F969C !important;
            font-weight: 400;
        }
    }

    >svg {
        position: absolute;
        width: 20rem;
        height: 21rem;

        top: 14rem;
        left: 16rem;
    }

    &.error {
        flex-wrap: wrap;

        input {
            border-color: #EF2C4F;

            &::placeholder {
                color: #EF2C4F;
            }
        }

        >svg {
            path {
                fill: #EF2C4F;
            }
        }
    }

    &.disabled {
        z-index: 1;

        input {
            border: 1rem solid var(--bg-gray, #F2F4F6);
            color: var(--Gray-dark, #626263);
            pointer-events: none;
            font-weight: 400;
            opacity: 0.7;
            position: relative;
            z-index: -1;
        }

        svg {
            opacity: 0.7;
        }
    }

    .change {
        position: absolute;
        right: 16rem;
        top: 50%;
        transform: translateY(-50%);
        color: var(--Blue, #2555FF);
        font-size: 14rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
    }

    .show {
        position: absolute;
        right: 16rem;
        top: 14rem;
        cursor: pointer;
        width: 22rem;
        height: 22rem;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 22rem;
            height: 22rem;
        }
    }

    .error-text {
        color: var(--Additional-Red, #EF2C4F);
        font-size: 12rem;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        margin-top: 8rem;
    }

    &-ai {
        width: 49rem;
        height: 49rem;
        min-width: 49rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 11rem;
        border: 1rem solid var(--bg-gray, #F2F4F6);
        margin-left: 6rem;

        svg {
            width: 26rem;
            height: 26rem;
            position: static;
            transform: none;
        }
    }
}
.error-text {
    color: var(--Additional-Red, #EF2C4F);
    font-size: 12rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-top: 8rem;
}
.brandname {
    &-items {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 6rem;
    }

    &-item {
        margin-right: 6rem;
        margin-bottom: 6rem;
        border: 1px solid #F2F4F6;
        border-radius: 100rem;
        height: 38rem;
        padding: 0 16rem;
        font-size: 14rem;
        font-weight: 500;
        line-height: 1.3;
        color: #000;
        display: flex;
        align-items: center;
        @media screen and (min-width: 500px) {
            font-size: 16rem;
        }
    }
    &-text {
        color: #8F969C;
        font-size: 12rem;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 32rem;
        @media screen and (min-width: 500px) {
            font-size: 14rem;
        }
    }

}

.textarea {
    position: relative;
    display: flex;
    align-items: center;

    textarea {
        border-radius: 11rem;
        border: 1rem solid var(--bg-gray, #F2F4F6);
        width: 100%;
        height: 164rem;
        padding: 16rem;
        color: var(--Black, #000);
        font-family: Italian Plate No2 Expanded;
        font-size: 14rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        outline: none;
        padding-bottom: 25rem;

        &::placeholder {
            color: var(--Gray-dark, #A7A8AA);
            font-weight: 400;
        }
    }

    &-bottom {
        position: absolute;
        bottom: 14rem;
        right: 16rem;
        display: flex;
        align-items: center;

        span {
            margin-right: 8rem;
            color: var(--Dark-Gray, #8F969C);
            font-family: "Italian Plate No2 Expanded";
            font-size: 12rem;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }

        svg {
            width: 14rem;
            height: 10rem;
        }
    }
}

.typing-block {
    border-radius: 8rem;
    border: 1rem solid #5BF0A5;
    background: #EFFDF6;
    padding: 10rem 15rem;
    transition: 0.3s;

    &--red {
        border: 1rem solid #EF2C4F;
        background: #FCEDF0;
    }
}

.skip {
    color: #000;

    text-align: center;
    font-family: Italian Plate No2 Expanded;
    font-size: 14rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    margin-top: 27rem;
    cursor: pointer;
}

.emojiRadio {

    &-wrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 5.75rem;
    }

    label {
        aspect-ratio: 1/1;
        display: block;

        cursor: pointer;

        &:nth-child(2),
        &:nth-child(4) {
            .emojiRadio-item {
                font-size: 16rem;
            }
        }
    }

    input {
        width: 0;
        height: 0;
        opacity: 0;
        visibility: hidden;
        position: absolute;

        &:checked+.emojiRadio-item {
            border: 1rem solid #5BF0A5;
        }
    }

    &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 10rem;
        border: 1rem solid var(--Light-Gray, #F2F4F6);
        color: #000;
        font-family: Italian Plate No2 Expanded, sans-serif;
        font-size: 24rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    &-bottom {
        margin-top: 10rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        font-family: Italian Plate No2 Expanded, sans-serif;
        font-size: 12rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        @media screen and (min-width: 500px) {
            font-size: 14rem;
        } 
    }
}

.choose-action {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10rem;

    &-btn {
        border-radius: 100rem;
        border: 1rem solid var(--Light-Gray, #F2F4F6);
        background: #FFF;
        height: 62rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        text-align: center;
        font-family: Italian Plate No2 Expanded, sans-serif;
        font-size: 16rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;

        svg {
            margin-right: 8rem;
        }
    }
}
.choose-video-bottom-text {
    margin-bottom: 0rem;
    font-weight: 400;
    font-size: 10rem;
    text-align: center;
    color: #fff;
    position: absolute;
    z-index: 2;
    left: 8rem;
    bottom: 10rem;
    padding: 0rem 10rem;
    height: 32rem;
    display: flex;
    align-items: center;
    backdrop-filter: blur(8px);
background: rgba(0, 0, 0, 0.32);
border-radius: 80rem;
    @media screen and (min-width: 500px) {
        font-size: 12rem;
    } 
}
.choose-video-top-text {
    margin-bottom: 8rem;
    text-align: center;
    font-size: 14rem;
    line-height: 1.2;
    font-weight: 600;
    @media screen and (min-width: 500px) {
        font-size: 16rem;
    } 
}
.choose-video-item {
    max-width: 157rem;
    @media screen and (min-width: 500px) {
        max-width: 200rem;
    } 
}
.preload {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    pointer-events: none;
    user-select: none;
}

.boosts {
    &-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 5rem;
        margin-bottom: 16rem;
    }

    &-item {
        &__number {
            color: var(--Blue, #2555FF);
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 32rem;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;

            span {
                font-weight: 700;
                font-size: 20rem;
            }

        }

        p {
            color: #000;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 12rem;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }
    }


}

.strategy {
    &-wrapper {
        margin-bottom: 32rem;
    }

    &-item {
        padding-left: 23rem;
        position: relative;
        margin-bottom: 24rem;

        &:after {
            position: absolute;
            left: 0;
            top: 0;
            width: 3rem;
            height: 100%;
            content: '';
            border-radius: 6rem;
            background: var(--Green, #5BF0A5);
        }

        &__title {
            color: #000;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 18rem;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
            margin-bottom: 10rem;
        }

        p {
            margin-bottom: 12rem;
        }

        ul {
            display: flex;
            grid-gap: 4rem;
            flex-wrap: wrap;

        }

        li {
            border-radius: 100rem;
            background: var(--Green, #5BF0A5);
            height: 28rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10rem;
            font-weight: 500;
            font-size: 12rem;
            color: #000;

            svg {
                margin-right: 6rem;
            }
        }

        &:last-child {
            margin-bottom: 0;

            &:after {
                background: var(--Blue, #2555FF);
            }

            li {
                background: var(--Blue, #2555FF);
                color: #FFF;
            }
        }
    }
}

.predict {
    &-wrapper {
        margin-top: 12rem;
        display: flex;
        justify-content: space-between;
        padding: 0 24.5rem;

    }

    &-item {
        color: #8F969C;
        font-family: Italian Plate No2 Expanded,
            sans-serif;
        font-size: 12rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
}

.welcome {
    max-height: calc(100vh - 16rem - 36rem - 32rem);
    overflow: auto;
    width: calc(100% + 10rem);
    padding-right: 10rem;
    margin-right: -10rem;
    padding-bottom: 60rem;

    &::-webkit-scrollbar {
        width: 3rem;
        right: 3rem;
        position: absolute;
        top: 0;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--Gray, #DCDFE2);
        border-radius: 12rem;
    }

    .works__step-line {
        background: url(../../public/img/line.svg);

        width: 2rem;
        height: calc(100% - 14rem);
        background-position: top center;
        background-size: 100% auto;
        transform: translate(0);
        left: 21rem;
        top: 44rem;
    }
    .works__step-icon svg {
        width: 24rem;
        height: 24rem;
    }
    .works__step-icon {
        position: static;

    }

    .works__step:last-child {
        .works__step-line {
            display: none;
        }
    }

    .works__step-icon--green {
        background: #5BF0A5;

        .works__step-line {
            background: url(../../public/img/line-green.svg);

        }
    }

    .works__steps {
        max-width: 100%;
    }
}

.term {
    height: calc(100vh - 16rem - 36rem - 32rem - 64rem);
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100% + 10rem);
    padding-right: 10rem;
    margin-right: -10rem;
    padding-bottom: 60rem;

    &-back {
        // position: absolute;
        position: relative;
        left: -19rem;
        top: -11rem;
        background: #fff;
        width: 46rem;
    }

    &::-webkit-scrollbar {
        width: 3rem;
        right: 3rem;
        position: absolute;
        top: 0;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--Gray, #DCDFE2);
        border-radius: 12rem;
    }

    p {
        color: #000;
        font-family: "Italian Plate No2 Expanded";
        font-size: 14rem;
        font-style: normal;
        font-weight: 400;
        line-height: 145%;
        margin-bottom: 12rem;
        a {
            font-weight: 700;
            color: #000;
            text-decoration: underline;
        }
    }
    .wp-block-group__inner-container {
        p {
            margin-bottom: 12rem;
        }
        
    }
    ul {
        li {
            margin-bottom: 10rem;
            position: relative;
            padding-left: 22rem;
            color: #000;
                display: block;
                font-size: 14rem;
                font-style: normal;
                font-weight: 400;
                line-height: 145%;
            a {
                color: #000;
                text-decoration: underline;
            }
            &::after {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                content: '';
                width: 6rem;
                height: 6rem;
                background: #000;
            }
        }
    }
    h2 {
        color: #000;
        font-family: "Italian Plate No2 Expanded";
        font-size: 32rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 24rem;
        margin-top: 50rem;
    }
    h3 {
        color: #000;
        font-family: "Italian Plate No2 Expanded";
        font-size: 22rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 24rem;
        margin-top: 40rem;
    }
    h4 {
        color: #000;
        font-family: "Italian Plate No2 Expanded";
        font-size: 20rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 24rem;
    }
    figure {
        margin: 0;
        overflow-x: auto;
        margin-bottom: 16rem;
    }
    table {
        border-collapse: collapse;
        
        td{
            border: 1px solid #D1D7DD;
            padding: 10rem;
        color: #000000;
        font-size: 14rem;
        line-height: 1.3;
        a {
            text-decoration: underline;
            color: #000000;
        }
        }
    }
}

.personal {
    width: calc(100% + 40rem);
    margin: 0 -20rem;
    pointer-events: none;

    .swiper-free-mode>.swiper-wrapper {
        transition-timing-function: linear;
    }
}

.skeleton-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;


    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 300%;
        height: 100%;
        background-color: rgba(#fff, 0.6);
        background-image:
            linear-gradient(90deg,
                rgba(#e2e5e7, 0),
                rgba(#e2e5e7, 0.8),
                rgba(#e2e5e7, 0));
        background-size: 40px 100%; // width of the shine
        background-repeat: no-repeat; // No need to repeat the shine effect
        background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
        animation: shine 1s ease infinite;
        opacity: 0.6;
    }
}

@keyframes shine {
    to {
        // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
        background-position: right -40px top 0;
    }
}

@keyframes loading {
    100% {
        transform: translateX(-100%) rotate(90deg);
    }
}

.videos img {
    filter: blur(3px);
}

.congrats {
    position: absolute;
    bottom: 32rem;
    left: -16rem;
    width: calc(100% + 32rem);
    border: 1px solid #F2F4F6;
    background: #fff;
    height: 76rem;
    display: flex;
    align-items: center;
    padding: 0 16rem;
    border-radius: 18rem;
    animation-name: customAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    &-icon {
        margin-right: 10rem;
    }

    svg {
        width: 44rem;
        height: 44rem;
    }

    strong {
        display: block;
        margin-bottom: 4rem;
        font-size: 15rem;
        font-weight: 600;
        line-height: 1.2;
        color: #000;
    }

    span {
        display: block;
        font-size: 12rem;
        font-weight: 500;
        line-height: 1.2;
        color: #8F969C;
    }

}

@keyframes customAnimation {
    from {
        transform: translateY(400rem);
    }

    to {
        transform: translateY(0rem);
    }
}

.brandname {
    position: fixed;
    width: 100%;
    height: 100%;
    
    background: rgba($color: #000000, $alpha: 0.5);
    top: 0;
    left: 0;
    z-index: 10;

    &-wrapper {
        max-width: 343rem;
        background: #FFF;
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        bottom: 16rem;
        padding: 36rem 20rem;
        border-radius: 28rem;
        max-height:  calc(var(--vh, 1vh) * 100 - 32rem);
    }

    &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 36rem;

        svg {
            width: 150rem;
            height: 32rem;
        }

        &-right {
            font-weight: 600;
            font-size: 14rem;
            color: #000;
            line-height: 1.2;
            @media screen and (min-width: 500px) {
                font-size: 16rem;
            }
        }
    }
}

// .step {
//     height: 100%;
//     display: block;
//     padding: 10rem 16rem 25rem;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;

//     .title {
//         font-weight: 500;
//         font-size: 20rem;
//         line-height: 120%;
//         color: #000000;
//         margin-bottom: 7rem;
//     }

//     .radio {
//         display: grid;
//         grid-template-columns: 1fr 1fr;
//         grid-column-gap: 18rem;
//         grid-row-gap: 20rem;
//         padding-bottom: 20rem;
//         margin-top: 20rem;

//         label {
//             cursor: pointer;

//             input {
//                 opacity: 0;
//                 width: 0;
//                 height: 0;
//                 position: absolute;

//                 &:checked+div {
//                     border: 1rem solid #C3FBE5;

//                     &:after {
//                         position: absolute;
//                         content: '';
//                         width: calc(100% + 2rem);
//                         height: calc(100% + 2rem);
//                         left: -1rem;
//                         top: -1rem;
//                         border: 2rem solid #C3FBE5;
//                         z-index: 3;
//                         border-radius: 10rem;
//                     }
//                 }
//             }
//         }
//     }
// }




.spin-loader {
    position: absolute;
    width: 26rem;
    height: 26rem;
    top: 50%;
    left: 50%;
transform: translate(-50%,-50%);
  }
  
  /* iOS Loader */
  .ios-loader .bar {
    width: 10%;
    height: 26%;
    background: #606060;
    position: absolute;
    left: 44.5%;
    top: 37%;
    border-radius: 50px;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    opacity: 0;
    -webkit-animation: fade 1s linear infinite;
    animation: fade 1s linear infinite;
  }
  
  @-webkit-keyframes fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .ios-loader .bar1 {
    -webkit-transform: rotate(-315deg) translate(0px, -142%);
    -ms-transform: rotate(-315deg) translate(0px, -142%);
    transform: rotate(-315deg) translate(0px, -142%);
    -webkit-animation-delay: -0.875s;
    animation-delay: -0.875s;
  }
  
  .ios-loader .bar2 {
    -webkit-transform: rotate(-270deg) translate(0px, -142%);
    -ms-transform: rotate(-270deg) translate(0px, -142%);
    transform: rotate(-270deg) translate(0px, -142%);
    -webkit-animation-delay: -0.75s;
    animation-delay: -0.75s;
  }
  
  .ios-loader .bar3 {
    -webkit-transform: rotate(-225deg) translate(0px, -142%);
    -ms-transform: rotate(-225deg) translate(0px, -142%);
    transform: rotate(-225deg) translate(0px, -142%);
    -webkit-animation-delay: -0.625s;
    animation-delay: -0.625s;
  }
  
  .ios-loader .bar4 {
    -webkit-transform: rotate(-180deg) translate(0px, -142%);
    -ms-transform: rotate(-180deg) translate(0px, -142%);
    transform: rotate(-180deg) translate(0px, -142%);
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  
  .ios-loader .bar5 {
    -webkit-transform: rotate(-135deg) translate(0px, -142%);
    -ms-transform: rotate(-135deg) translate(0px, -142%);
    transform: rotate(-135deg) translate(0px, -142%);
    -webkit-animation-delay: -0.375s;
    animation-delay: -0.375s;
  }
  
  .ios-loader .bar6 {
    -webkit-transform: rotate(-90deg) translate(0px, -142%);
    -ms-transform: rotate(-90deg) translate(0px, -142%);
    transform: rotate(-90deg) translate(0px, -142%);
    -webkit-animation-delay: -0.25s;
    animation-delay: -0.25s;
  }
  
  .ios-loader .bar7 {
    -webkit-transform: rotate(-45deg) translate(0px, -142%);
    -ms-transform: rotate(-45deg) translate(0px, -142%);
    transform: rotate(-45deg) translate(0px, -142%);
    -webkit-animation-delay: -0.125s;
    animation-delay: -0.125s;
  }
  
  .ios-loader .bar8 {
    -webkit-transform: rotate(0deg) translate(0px, -142%);
    -ms-transform: rotate(0deg) translate(0px, -142%);
    transform: rotate(0deg) translate(0px, -142%);
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  
  @-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }


  .hand {
    position: absolute;
    bottom: -40rem;
    right: 30rem;
    transform: translatey(0px);
    animation: upDown 0.7s infinite;
    cursor: pointer;
    z-index: 1;
}

@keyframes upDown {
    0% {
        transform: translatey(0) translatex(0);
    }

    50% {
        transform: translatey(-50rem) translatex(-30rem);
    }

    100% {
        transform: translatey(0) translatex(0);
    }
}