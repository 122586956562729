.priceInfo {
    display: flex;
    align-items: center;
    font-size: 12rem;
    line-height: 1.4;
    color: #8F969C;
    // position: relative;
    span{
        margin-left: 6rem;
        cursor: pointer;
        position: relative;
        b {
            display: block;
            position: absolute;
            bottom: calc(100% + 4.5rem);
            left: 50%;
            transform: translateX(-50%);
            content: '';
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 6rem 10rem 0 10rem;
            border-color: #000 transparent transparent transparent;
            pointer-events: none;
        }
    }
    &-wrapper {
        position: absolute;
        bottom: calc(100% + 9rem);
        background: #000;
        padding: 17rem 12rem 17rem;
        border-radius: 14rem;

    }
    &__title {
        font-size: 13rem;
        line-height: 1.3;
        font-weight: 600;
        color: #fff;
        margin-bottom: 10rem;
    }
    p {
        font-size: 13rem;
        line-height: 1.3;
        font-weight: 400;
        color: #fff;
        i {
            font-weight: 400;
            font-style: normal;
            text-decoration: underline;
        }
    }
    &__close {
        position: absolute;
        top: 17.5rem;
        right: 12rem;
        cursor: pointer;
        svg {
            width: 17rem;
            height: 17rem;
        }
    }
}