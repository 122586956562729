.price-container {
    position: relative;
    height: 100%;

    &:after {
        position: absolute;
        width: 100%;
        content: '';
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 1.05%, #FFF 11.78%);
        height: 118rem;
        top: -36rem;
        left: 0;
        z-index: 1;
    }

    h1 {
        position: relative;
        z-index: 2;
    }
}

.price {
    max-height: 600rem;
    position: relative;
    height: calc(var(--vh, 1vh) * 100 - 16rem - 62rem - 36rem - 32rem - 36rem);
    overflow: auto;
    padding-right: 11rem;
    width: calc(100% + 11rem);
    margin-right: -11rem;
    margin-bottom: -62rem;
    padding-bottom: 84rem;
    padding-top: 90rem;
    margin-top: -90rem;
    @media screen and (min-width: 500px) {
        height: auto;
        max-height:  calc(var(--vh, 1vh) * 100 - 64rem - 16rem - 50rem - 62rem - 16rem - 16rem - 10rem - 50rem);
        margin-bottom: 0;
        padding-bottom: 0;
    } 
    &::-webkit-scrollbar {
        width: 3rem;
        right: 3rem;
        position: absolute;
        top: 0;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--Gray, #DCDFE2);
        border-radius: 12rem;
    }

    &-plans {
        &__top {
            display: flex;
            width: 100%;

            &-icon {
                width: 18rem;
                height: 18rem;
                border-radius: 50%;
                border: 1rem solid #CAD4DD;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10rem;

                span {
                    width: 10rem;
                    height: 10rem;
                    border-radius: 50%;
                    background: #2555FF;
                    opacity: 0;
                }
            }

            &-trial {
                margin-left: auto;
                color: var(--Gray-dark, #8F969C);
                &-last {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                i {
                    
                    font-size: 10rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-decoration: line-through;
                    font-style: normal;
                    @media screen and (min-width: 500px) {
                        font-size: 12rem;
                    } 
                }

                small {
  
                    font-size: 20rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 0.9;
                    @media screen and (min-width: 500px) {
                        font-size: 22rem;
                    } 
          
                }

                span {
                    font-size: 10rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%;
                    @media screen and (min-width: 500px) {
                        font-size: 12rem;
                    } 
                }
            }
        }

        &__plan {
            border-radius: 12rem;
            border: 1rem solid var(--Light-Gray, #F2F4F6);
            margin-bottom: 8rem;
            padding: 20rem;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }

            &-title {
                color: var(--Black, #000);
                font-family: Italian Plate No2 Expanded;
                font-size: 16rem;
                font-style: normal;
                font-weight: 600;
                line-height: 110%;
                margin-bottom: 6rem;
                @media screen and (min-width: 500px) {
                    font-size: 18rem;
                } 
            }

            &-price {
                color: var(--black, #8F969C);
                font-family: Italian Plate No2 Expanded;
                font-size: 14rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                display: flex;
                align-items: center;
                @media screen and (min-width: 500px) {
                    font-size: 16rem;
                } 
                svg {
                    width: 10rem;
                    height: 8rem;
                    margin: 0 4rem;
                }

                span {
                    text-decoration: line-through;
                    text-decoration-color: #EF2C4F;
                }
            }

            .price-plans__plan-active-trial {
                border-radius: 12rem 12rem 0rem 0rem;
                background: var(--Green, #5BF0A5);
                width: calc(100% + 40rem);
                height: 28rem;
                margin: -20rem -20rem 20rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--Black, #000);
                text-align: center;
                font-family: Italian Plate No2 Expanded;
                font-size: 12rem;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;

            }

            &--active {

                .price-plans__plan-price {
                    color: #000;

                    span {
                        color: #8F969C;
                    }
                }

                &:nth-child(2) {
                    // .price-plans__plan-active-trial {
                    //     background: var(--Blue, #2555FF);
                    //     color: #fff;
                    // }
                }

                .price-plans__top-icon {
                    border-color: #2555FF;

                    span {
                        opacity: 1;
                    }
                }

                .price-plans__top-trial {
                    color: #000;
                }
            }

        }

        &__features {
            margin-top: 20rem;

            li {
                display: flex;
                align-items: center;

                svg {
                    width: 13rem;
                    height: 13rem;
                    margin-right: 10rem;
                }

                color: #000;
                font-family: Italian Plate No2 Expanded;
                font-size: 12rem;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
                margin-bottom: 10rem;

                &:last-child {
                    margin-bottom: 0;
                }

                &.deactive {
                    color: var(--Gray-dark, #8F969C);
                    text-decoration: line-through;
                }
            }
        }
    }

    &-bottom {
        padding-top: 20rem;
        padding-bottom: 12rem;
        color: #8F969C;
        text-align: center;
        font-family: Italian Plate No2 Expanded;
        font-size: 12rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 14.08%);
        position: relative;
        z-index: 1;
    }
}