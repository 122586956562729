.summary-container {
    position: relative;
    height: 100%;
    
    &:after {
        position: absolute;
        width: 100%;
        content: '';
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 1.05%, #FFF 11.78%);
        height: 118rem;
        top: -36rem;
        left: 0;
        z-index: 1;
    }
    h1 {
        position: relative;
        z-index: 2;
    }
}
.summary {
    max-height: 600rem;
    position: relative;
    height:  calc(var(--vh, 1vh) * 100 - 16rem - 64rem - 36rem - 32rem - 36rem);
    
    overflow: auto;
    padding-right: 11rem;
    width: calc(100% + 11rem);
    margin-right: -11rem;
    margin-bottom: -62rem;
    padding-bottom: 84rem;
    padding-top: 90rem;
    margin-top: -90rem;
    @media screen and (min-width: 500px) {
        height:  calc(var(--vh, 1vh) * 100 - 64rem - 16rem - 50rem - 62rem - 16rem - 16rem - 10rem);
        max-height: 100%;
    }
    &::-webkit-scrollbar {
        width: 3rem;
        right: 3rem;
        position: absolute;
        top: 0;
      }
      
    &::-webkit-scrollbar-track {
        background: transparent; 
      }
    &::-webkit-scrollbar-thumb {
        background: var(--Gray, #DCDFE2);
        border-radius: 12rem; 
    }
    &-block {
        margin-bottom: 32rem;
        &:last-child {
            margin-bottom: 0;
        }
        &__title {
            color: #000;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 16rem;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
            margin-bottom: 12rem;
            @media screen and (min-width: 500px) {
                font-size: 18rem;
            } 
        }
        &-line {
            display: flex;
            justify-content: space-between;
            margin-bottom: 6rem;
            &:last-child {
                margin-bottom: 0;
            }
            span {
                color: var(--Dark-Gray, #8F969C);
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 14rem;
                font-style: normal;
                font-weight: 400;
                line-height: 34rem;
                @media screen and (min-width: 500px) {
                    font-size: 16rem;
                } 
            }
            &-items {
                display: flex;
                flex-wrap: wrap;
                grid-gap: 4rem;
                justify-content: flex-end;
                max-width: 240rem;
 

            }
            &-item {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 34rem;
                padding: 0 14rem;
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 14rem;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;
                border-radius: 100rem;
                border: 1rem solid var(--Light-Gray, #F2F4F6);
                text-align: center;
                @media screen and (min-width: 500px) {
                    font-size: 16rem;
                } 
            }
        }
    }
    &-bottom {
        position: relative;
        z-index: 1;
        padding-top: 20rem;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 33.77%);
        height: 82rem;
        margin-bottom: -62rem;
    }
}