.block-home {
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (min-width: 991px) {
        padding-top: 57rem;
    }

    .block {
        @media screen and (min-width:991px) {
            width: 1160rem !important;
        }
    }

    .css-mqftaq {
        height: 100%;

        >div {
            height: 100%;
        }
    }
}

.hero {
    &__img {
        position: absolute;
        width: calc(100% + 32rem);
        top: 0rem;
        left: -16rem;
        background: #5BF0A5;
        z-index: -1;

        @media screen and (min-width: 500px) {
            height: 100%;
            display: flex;
            align-items: flex-end;
        }

        img {
            width: 100%;
            position: relative;
            z-index: 1;

            &:nth-child(2) {
                position: absolute;
                left: 120rem;
                top: 83rem;
                width: 137rem;
                border-radius: 15rem;
                z-index: -2;

                @media screen and (min-width: 500px) {
                    top: auto;
                    bottom: 385rem;
                    left: 158rem;
                    width: 182rem;
                }
            }
        }

        &:before {
            position: absolute;
            content: '';
            bottom: 0rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: calc(100% + 50rem);
            background: #5BF0A5;
            z-index: -2;
        }

        &:after {
            position: absolute;
            content: '';
            bottom: -5rem;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100vw + 10rem);
            height: calc(100% - 112rem);
            max-height: 558rem;

            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 57%, rgb(0, 0, 0) 100%);
            background-size: 100% 100%;
            z-index: 1;
        }

        .hero__hand-images {
            position: absolute;
            left: 120rem;
            top: 83rem;
            width: 137rem;
            border-radius: 15rem;
            z-index: -1;
            overflow: hidden;
            aspect-ratio: 1500/3248;

            @media screen and (min-width: 500px) {
                top: auto;
                bottom: 385rem;
                left: 158rem;
                width: 182rem;
            }

            img {
                width: 100%;
                transform: translateX(100%) translateY(-50%);
                position: absolute;
                left: 0;
                top: 51%;
                transition: 0s ease-in;
                z-index: 0;

                &.active {
                    transition: 0.3s ease-out;
                    transform: translateX(0%) translateY(-50%);
                }
            }
        }
    }
}



.home1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 11rem;
    align-items: center;

    h1 {
        color: #FFFFFF;
        font-size: 37rem;
    }

    // &:after {
    //     position: absolute;
    //     width: calc(100% + 32rem);
    //     height: calc(100% + 16rem);
    //     content: '';
    //     left: -16rem;
    //     bottom: -16rem;
    //     background: #5BF0A5;
    //     // background-image: url(../../../public/img/hand.png);
    //     background-size: 100% auto;
    //     z-index: -2;
    // }
    // &::before {
    //     position: absolute;
    //     content: "";
    //     width: calc(100% + 32rem);
    //     height: calc(100% + 16rem);
    //     content: '';
    //     left: -16rem;
    //     bottom: -16rem;
    //     height: 400rem;

    //     background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 57%, rgb(0, 0, 0) 100%);
    //     background-size: 100% 100%;
    //     z-index: -1;
    // }
    &__logo {
        svg {
            width: 79rem;
            height: 22rem;
        }
    }
}



.home2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 11rem;
    align-items: center;
    width: calc(100% + 12rem);
    margin: 0 -6rem;

    h1 {
        text-align: center;
        margin-bottom: 34rem;

        br {
            display: none;
        }
    }

    p {
        font-weight: 400;
        font-size: 14rem;
        line-height: 140%;
        text-align: center;
        color: #000;
        margin-bottom: 16rem;
    }

    &:after {
        position: absolute;
        width: calc(100% + 32rem);
        height: calc(100% + 16rem);
        content: '';
        left: -16rem;
        bottom: -16rem;
        background: #fff;
 
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center 32rem;
        z-index: -2;
    }

    &:before {
        @media screen and (min-width: 500px) {
            position: absolute;
            width: 100vw;
            height: calc(100% + 100rem);
            content: '';
            left: 50%;
            transform: translateX(-50%);
            bottom: -50rem;
            background: #fff;
            z-index: -2;
        }
    }


    &-content {
        position: relative;
        &:after {
            position: absolute;
            width: calc(100% + 32rem);
            height: calc(100% + 16rem);
            content: '';
            left: -16rem;
            bottom: -16rem;
            background: #fff;
            background-image: url(../../../public/img/home2.png);
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: center bottom;
            z-index: -1;
        }
    }
}

.page2 {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    height: 100%;
    padding-top: 11rem;
    align-items: center;
    width: calc(100% + 12rem);
    margin: 0 -6rem;

    &-content {
        // padding-top: 388rem;
        flex: 1 1 0%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 32rem;
        position: relative;
        &:after {
            position: absolute;
            width: calc(100% + 32rem);
            height: calc(100% + 16rem);
            content: '';
            left: -16rem;
            bottom: -16rem;
            background: #fff;
            background-image: url(../../../public/img/page2.png);
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: center bottom;
            z-index: -1;
        }
        @media screen and (min-width: 500px) {
            padding-top: 505rem;
        }
    }

    &:before {
        @media screen and (min-width: 500px) {
            position: absolute;
            width: 100vw;
            height: calc(100% + 100rem);
            content: '';
            left: 50%;
            transform: translateX(-50%);
            bottom: -50rem;
            background: #fff;
            z-index: -2;
        }
    }

    h1 {
        text-align: center;
        margin-bottom: 34rem;

        br {
            display: none;
        }
    }

    p {
        font-weight: 400;
        font-size: 14rem;
        line-height: 140%;
        text-align: center;
        color: #000;
        margin-bottom: 16rem;
    }

    &:after {
        position: absolute;
        width: calc(100% + 32rem);
        height: calc(100% + 16rem);
        content: '';
        left: -16rem;
        bottom: -16rem;
        background: #fff;

        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center 32rem;
        z-index: -2;
    }

  
}

.home3 {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    height: 100%;
    padding-top: 11rem;
    align-items: center;
    width: calc(100% + 12rem);
    margin: 0 -6rem;

    &__logo {
        margin-bottom: 53rem;

        svg {
            width: 79rem;
            height: 22rem;
        }
    }

    h1 {
        text-align: center;
        margin-bottom: 12rem;

        br {
            display: none;
        }
    }

    p {
        font-weight: 400;
        font-size: 14rem;
        line-height: 140%;
        text-align: center;
        color: #000;
        margin-bottom: 32rem;

        span {
            font-weight: 700;
        }
    }

    &:after {
        position: absolute;
        width: calc(100% + 32rem);
        height: calc(100% + 16rem);
        content: '';
        left: -16rem;
        bottom: -16rem;
        background: #fff;
        // background-image: url(../../../public/img/home2.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center 32rem;
        z-index: -2;
    }

    &:before {
        @media screen and (min-width: 500px) {
            position: absolute;
            width: 100vw;
            height: calc(100% + 100rem);
            content: '';
            left: 50%;
            transform: translateX(-50%);
            bottom: -50rem;
            background: #fff;
            z-index: -2;
        }
    }

    &-content {}
}


.page5 {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    height: 100%;
    padding-top: 11rem;
    align-items: center;

    &__logo {
        margin-bottom: 53rem;

        svg {
            width: 79rem;
            height: 22rem;
        }
    }

    h1 {
        text-align: center;
        margin-bottom: 12rem;

        br {
            display: none;
        }
    }

    p {
        font-weight: 400;
        font-size: 14rem;
        line-height: 140%;
        text-align: center;
        color: #000;
        margin-bottom: 32rem;

        span {
            font-weight: 700;
        }
    }

    &:before {
        @media screen and (min-width: 500px) {
            position: absolute;
            width: 100vw;
            height: calc(100% + 100rem);
            content: '';
            left: 50%;
            transform: translateX(-50%);
            bottom: -50rem;
            background: #fff;
            z-index: -2;
        }
    }

    &:after {
        position: absolute;
        width: calc(100% + 32rem);
        height: calc(100% + 16rem);
        content: '';
        left: -16rem;
        bottom: -16rem;
        background: #fff;
        // background-image: url(../../../public/img/home2.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center 32rem;
        z-index: -2;
    }

    &-content {
        width: calc(100% + 40rem);
        margin: 0 -20rem;
        position: relative;

        p {
            position: absolute;
            width: 100%;
            left: 0;
            top: 84rem;
        }

        &::after {
            position: absolute;
            content: '';
            width: 20rem;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(90deg, white, rgba(#FFFFFF, 0));
            z-index: 2;
        }

        &::before {
            position: absolute;
            content: '';
            width: 20rem;
            height: 100%;
            top: 0;
            right: 0;
            background: linear-gradient(-90deg, white, rgba(#FFFFFF, 0));
            z-index: 2;
        }
    }

    .swiper-pagination {
        position: static;
        margin-top: 20rem;
        transform: none !important;
        justify-content: center;
        display: flex;
        width: 100% !important;
    }

    .swiper-pagination-bullet {
        position: static;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background: #CAD4DD;
        transform: none;
        opacity: 1;
        margin: 0 2rem !important;
        transition: width 0.3s !important;
    }

    .swiper-pagination-bullet-active {
        width: 10rem;
        border-radius: 9rem;
        background: #000;
    }

}


.page6 {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // height: 100%;
    padding-top: 11rem;
    align-items: center;
    width: calc(100% + 12rem);
    margin: 0 -6rem;
    padding-bottom: 118rem;

    &:before {
        @media screen and (min-width: 500px) {
            position: absolute;
            width: 100vw;
            height: calc(100% + 100rem);
            content: '';
            left: 50%;
            transform: translateX(-50%);
            bottom: -50rem;
            background: #fff;
            z-index: -2;
        }
    }

    &__logo {
        margin-bottom: 53rem;

        svg {
            width: 79rem;
            height: 22rem;
        }
    }

    h1 {
        text-align: center;
        margin-bottom: 12rem;

        br {
            display: none;
        }
    }

    p {
        font-weight: 400;
        font-size: 14rem;
        line-height: 140%;
        text-align: center;
        color: #000;
        margin-bottom: 32rem;

        span {
            font-weight: 700;
        }
    }

    &:after {
        position: fixed;
        width: calc(100% + 32rem);
        height: calc(100% + 16rem);
        content: '';
        left: -16rem;
        bottom: -16rem;
        background: #fff;
        // background-image: url(../../../public/img/home2.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center 32rem;
        z-index: -2;
    }

    &-content {
        width: 100%;

    }

    &-featured {
        &-wrapper {
            max-width: 267rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20rem auto 0;
        }
    }

    &-button {
        position: fixed;
        bottom: 0rem;
        width: 100%;
        padding: 0 30rem 24rem;
        background: #fff;
    }

}




.page7 {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // height: 100%;
    padding-top: 11rem;
    align-items: center;
    width: calc(100% + 12rem);
    margin: 0 -6rem;
    padding-bottom: 32rem;
    &:before {
        @media screen and (min-width: 500px) {
            position: absolute;
            width: 100vw;
            height: calc(100% + 100rem);
            content: '';
            left: 50%;
            transform: translateX(-50%);
            bottom: -50rem;
            background: #fff;
            z-index: -2;
        }
    }

    &__logo {
        margin-bottom: 53rem;

        svg {
            width: 79rem;
            height: 22rem;
        }
    }

    h1 {
        text-align: center;
        margin-bottom: 12rem;

        br {
            display: none;
        }
    }

    p {
        font-weight: 400;
        font-size: 14rem;
        line-height: 140%;
        text-align: center;
        color: #000;
        margin-bottom: 32rem;

        span {
            font-weight: 700;
        }
    }

    &:after {
        position: fixed;
        width: calc(100% + 32rem);
        height: calc(100% + 16rem);
        content: '';
        left: -16rem;
        bottom: -16rem;
        background: #fff;
        // background-image: url(../../../public/img/home2.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center 32rem;
        z-index: -2;
    }

    &-content {
        width: 100%;
    }

    &-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 11rem;
    }

    &-item {
        background: #5bf0a5;
        border-radius: 16rem;
        padding: 11rem 3rem 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 104rem;
            height: 110rem;
        }

        &-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #000;
            width: 100%;
            border-radius: 14rem;
            padding: 10rem 12rem;

            span {
                font-family: "Italian Plate No2 Expanded", sans-serif;
                font-weight: 600;
                font-size: 14rem;
                color: #fff;
            }

            .arrow {
                background: #5bf0a5;
                width: 26rem;
                height: 26rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }
        }
    }

}

.wrapper.block-home {
    .block {
        height: 100%;
        background: transparent;
        padding: 0;
        border-radius: 0;
        overflow: visible;
    }

    .block-animate {
        height: 100% !important;

        >div {
            height: 100%;
        }

        .block-height {
            height: 100%;

            >div {
                height: 100%;
            }
        }
    }
}