.better {
    &-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16rem;
        &:last-child {
            margin-bottom: 0;
        }
        &-left {
            display: flex;
            align-items: center;

            img {
                width: 32.56rem;
                height: 32.56rem;
                margin-right: 5.4rem;
                object-fit: cover;
            }

            span {
                border-radius: 0rem 10.854rem 10.854rem 10.854rem;
                background: var(--Light-Gray, #F2F4F6);
                padding: 7rem 11rem;
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 13rem;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
                @media screen and (min-width: 500px) {
                    font-size: 15rem;
                } 
            }

        }
        &-right {
            display: flex;
            align-items: center;

            img {
                width: 32.56rem;
                height: 32.56rem;
                margin-left: 5.4rem;
            }

            span {
                border-radius: 10.854rem 10.854rem 0rem 10.854rem;
                background: var(--Green, #5BF0A5);
                padding: 7rem 11rem;
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 13rem;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
                @media screen and (min-width: 500px) {
                    font-size: 15rem;
                } 
            }

        }
    }
}