.signup-bottom {
    margin-top: 24rem;

    &-checkbox {
        display: flex;
        margin-bottom: 24rem;

        label {
            display: flex;
            align-items: flex-start;
        }

        p {
            color: #000;
            font-family: Italian Plate No2 Expanded;
            font-size: 13rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;

            span {
                font-weight: 700;
                line-height: 150%;
                text-decoration-line: underline;
                cursor: pointer;
            }
        }

    }

    &-text {
        color: var(--Dark-Gray, #8F969C);
        text-align: center;
        font-family: Italian Plate No2 Expanded;
        font-size: 12rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        max-width: 228rem;
        margin: 0 auto;
    }
}
.code-inputs {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 8rem;
    input {
        width: 100%;
        height: 49rem;
        border-radius: 11rem;
        border: 1rem solid #F2F4F6;
        text-align: center;
        color: #000;
        font-size: 14rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
    }
}