.benefits {
    display: flex;
    &-item {
        width: 50%;
        padding-top: 16rem;
        padding-bottom: 18rem;
        &__title {
            font-weight: 700;
            font-size: 16rem;
            color: #000;
            margin-bottom: 10rem;
            text-align: center;
            line-height: 1.2;
            @media screen and (min-width: 500px) {
                font-size: 18rem;
            } 
        }
        &:first-child {
            background: #FFE3E8;
            border-radius: 12rem 0 0 12rem;
        }
        &:last-child {
            background: #2555FF;
            border-radius: 0 12rem 12rem 0;
            .benefits-item__title {
                color: #FFF;
            }
            ul {
                li {
                    color: #FFF;
                }
            }
        }
        ul {
            padding: 0 12.5rem;
            li {
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 13rem;
                color: #000;
                margin-bottom: 11rem;
                line-height: 1.3;
                @media screen and (min-width: 500px) {
                    font-size: 15rem;
                } 
                &:last-child {
                    margin-bottom: 0;
                }
                svg {
                    width: 17rem;
                    height: 17rem;
                    margin-right: 6rem;
                    @media screen and (min-width: 500px) {
                        width: 21rem;
                        height: 21rem;
                    } 
                }
            }
        }

    }
}