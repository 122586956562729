.radio {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
        width: 44rem;
        min-width: 44rem;
        height: 44rem;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 12rem;
    }
    span {
        display: flex;
        align-items: center;
    }
    color: #000;
    font-family: Italian Plate No2 Expanded, sans-serif;
    font-size: 14rem;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    @media screen and (min-width: 500px)  {
        font-size: 16rem;
    }
    &Icon {
        width: 18px;
        height: 18px;
        margin-left: auto;
                display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 100%;
            height: 100%;
            
            rect {
                transform-origin: 0 0!important;
                stroke: #CAD4DD;
            }
            circle {
                transform-origin: 0 0!important;
                opacity: 0;
            }
        }
        // border-radius: 50%;
        // // display: flex;
        // // align-items: center;
        // // justify-content: center;
        // border: 1px solid #CAD4DD;
        // margin-left: auto;
        // transition: 0.25s;
        // position: relative;
        // svg {
        //     width: 12px;
        //     height: 12px;
        //     opacity: 0;
        //     transition: 0.25s;
        //     transform: scale(0.77);
        //     transform: translate(-50%, -50%);
        //     position: absolute;
        //     top: 50%; 
        //     left: 50%;
        // }
        &.error {
            border-color: #EF2C4F!important;
            svg {
                rect {
                    stroke: #EF2C4F!important;
                }
            }
        }
    }
    input {
        width: 0;
        height: 0;
        visibility: hidden;
        pointer-events: none;

    }
}