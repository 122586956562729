.block-ai {
    padding: 36rem 36rem 32rem;
    height: 100%!important;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: flex-end!important;
    &.wrapper {
        .block {
            margin: 0 auto;
            padding: 0;
            background: transparent;
            border-radius: 0;
            width: 100%;
        }
        .block-animate {
            padding: 0;
            height: 100%;
 
        }
        .block-button {
            padding: 0;
        }
    }
    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 376rem;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 26.88%);   
        z-index: 0;
    }
    .css-mqftaq {
        height: 100%;
        position: relative;
        z-index: 1;
    }
}
.ai {
   
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-top {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20rem;
        &-line {
            background: rgba(255, 255, 255, 0.27);
            border-radius: 21rem;
            width: 49rem;
            height: 3rem;
            margin: 0 1.5rem;
            span{
                display: block;
                width: 100%;
                height: 100%;
                background: #fff;
                border-radius: 21rem;
            }
        }
    }

    &-img {
       margin-bottom: 32rem;
    }
    &-text {
        position: relative;
        margin-bottom: 30rem;
        p {
            color: rgba(255, 255, 255, 0.14);
            font-family: "Italian Plate No2 Expanded";
            font-size: 26rem;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            @media screen and (min-width: 500px) {
                font-size: 32rem;
            }
        }
        .ai-type {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            p {
                color: var(--white, #FFF);
                font-family: "Italian Plate No2 Expanded";
                font-size: 26rem;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                white-space: nowrap;
                display: block;
                @media screen and (min-width: 500px) {
                    font-size: 32rem;
                }
                &::after {
                    color: #2555FF;
                    display: inline-block;
                    font-size: 30rem;
                    line-height: 26rem;
                    @media screen and (min-width: 500px) {
                        font-size: 34rem;
                    }
                }
            }
            
           
        }
        .hide-cursor {
            p::after {
                display: none!important;
            }
        }
    }
}

// @media screen and (min-width: 500px)  {
//     .ai-img {
//         max-width: 350rem;
//         margin: 0 auto 32rem;
//     }
// }

// @media screen and (min-width: 1500px)  {
//     .ai-img {
//         max-width: 100%;
//         margin: 0 auto 32rem;
//     }
// }