.welcometo {
    height:  calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%!important;
    left: 0;
    top: 0rem;
    background: #fff;
    z-index: 1;
    &-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 246rem;
        left: 50%;
        transform: translateX(-50%);
        span{
            font-weight: 600;
            font-size: 14rem;
            color: #000;
            line-height: 1.2;
            margin-bottom: 8rem;
        }
        svg {
            width: 220rem;
            height: 47rem;
        }
    }
    h3 {
        font-weight: 700;
        color: #000;
        font-size: 26rem;
        line-height: 1.2;
        position: absolute;
        top: 246rem;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        opacity: 0;
    }
    &-text {
        &--1 {
            top: 236rem;
            max-width: 303rem;
        }
        &--2 {
            top: 236rem;
            max-width: 303rem;
        }
        &--3 {
            top: 236rem;
            max-width: 225rem;
        }
        &--4 {
            top: 236rem;
            max-width: 303rem;
        }
        &--5 {
            top: 236rem;
            max-width: 303rem;
        }
    }
    .circle {
        border-radius: 50%;
        background: #5BF0A5;
        width: 110vh;
        height: 110vh;
        position: absolute;
        top: calc(50% - 120rem);
        left: 50%;
        transform: translate(-50%,-50%);
        transform-origin: center center;
        z-index: -1;
    }
}