.rangeSlider {
    margin-top: 80rem;

    &-value {
        color: #000;
        text-align: center;
        font-family: Italian Plate No2 Expanded, sans-serif;
        font-size: 22rem;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 32rem;
    }
    &-typing {
        min-height: 58rem;
        margin-bottom: 32rem;
        margin-top: 24rem;
    }

    &-slider {
        display: flex;
        align-items: center;

        .fisrt {
            width: 14px;
            height: 6px;
            background: #2555FF;
            border-radius: 12px 0 0 12px;
        }

        .last {
            width: 14px;
            height: 6px;
            background: #E9EEFF;
            border-radius: 0 12px 12px 0;
        }
    }

    .MuiSlider-root {
        padding: 8px 0px;
        height: 6px;
        border-radius: 0px;
    }

    .MuiSlider-thumb {
        width: 28px;
        height: 28px;
        border: 2px solid #FFF;
        box-shadow: none !important;
        background: #2555FF;

        &:after {
            display: none;
        }

        &:before {
            display: none;
        }
    }

    .MuiSlider-valueLabel {
        display: none;
    }

    .MuiSlider-rail {
        background: #E9EEFF;
        opacity: 1;
    }

    .MuiSlider-track {
        background: #2555FF;
    }

    .MuiSlider-mark {
        width: 2px;
        height: 10px;
        border-radius: 100px;
        background: #CFD5EC;
    }

    .MuiSlider-markActive {
        background: #2555FF;
    }
}