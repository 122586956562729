.before {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 9rem / 2));
    grid-gap: 9rem;
  
    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-info {
            padding: 5rem 8rem 4rem;
            border-radius: 6rem;
            background: #EF2C4F;
            color: #FFF;
            text-align: center;
            font-family: "Italian Plate No2 Expanded";
            font-size: 14rem;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            position: relative;
            margin-bottom: 14rem;
            max-width: 125rem;
            @media screen and (min-width: 500px) {
                max-width: 140rem;
            }  
            @media screen and (min-width: 500px) {
                font-size: 16rem;
            } 
            svg {
                width: 13rem;
                height: 6rem;
                position: absolute;
                top: calc(100% - 1rem);
                left: 50%;
                transform: translateX(-50%);
            }
            &--green {
                background: var(--Green, #5BF0A5);
                color: var(--black, #000);
            }
        }
        &__img {
            position: relative;
            width: 100%;
            margin-bottom: 20rem;
            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                border-radius: 10rem;
            }
            svg {
                width: 25rem;
                height: 25rem;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -13rem;
            }
        }
        &-bottom {
            display: flex;
            justify-content: center;
        
            width: 100%;
            p {
                display: flex;
                align-items: center;
                overflow: hidden;
                color: var(--Gray-dark, #A7A8AA);
                text-overflow: ellipsis;
                font-family: "Italian Plate No2 Expanded";
                font-size: 10rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                @media screen and (min-width: 500px)  {
                    font-size: 14rem;
                }
                span {
                    color: #000;
                    font-size: 10rem;
                    margin-left: 3rem;
                    margin-right: 2rem;
                    @media screen and (min-width: 500px)  {
                        font-size: 14rem;
                    }
                }
                svg {
                    width: 10rem;
                    height: 10rem;
                    // margin-left: 5rem;
                    @media screen and (min-width: 500px)  {
                        width: 14rem;
                    height: 14rem
                    }
                }
            }
        }
    }
}