.strategy {
    &-top {
        display: flex;
        justify-content: space-between;
        padding: 20rem 16rem 0;
        border: 1rem solid #F2F4F6;
        border-radius: 12rem;
        margin-bottom: 11rem;
        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            &__img {
                width: 64rem;
                height: 64rem;
                border-radius: 8rem;
                background: #F2F4F6;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                font-size: 10rem;
                line-height: 160%;
                color: #000;
                margin-bottom: 9rem;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 8rem;
                }
            }
            span {
                border: 1rem solid #F2F4F6;
                padding: 4rem 8rem 3rem;
                font-weight: 700;
                font-size: 10rem;
                line-height: 160%;
                color: #000;
                border-radius: 100rem;
                margin-bottom: -11rem;
                background: #fff;
            }
        }
    }
}