
@media screen and (min-width: 500px)  {
    html {
        font-size: 0.9px;
    }
    .container {
        max-width: 100%;

    }
    .wrapper {
        background: url(../../public/img/desktop.webp);
        background-repeat: no-repeat;
        background-size: 1920rem auto;
        background-position: center;
        padding-bottom: 50rem;
        padding-top: 50rem;
        justify-content: flex-end;
        .block {
            width: 464rem;
            margin: 0 auto;
        }
        &.block-white {
            .block {
                margin: 0 auto;
                padding-bottom: 0 !important;
            }
            .block-height {
                justify-content: flex-end;
                .css-mqftaq {
                    height: auto;
                }
            }
          
        }
        // &.block-welcome {
        //     overflow: auto;
        //     padding-bottom: 50rem;
        //     .block {
        //         // height: 100%;
        //         overflow: visible;
        //         >div {
        //             height: 100%;
        //         }
        //     }
        //     .welcome {
        //         overflow: visible;
        //         padding-bottom: 50rem;
        //         max-height: none;
        //     }
        // }
    }
    .header {
        max-width: 1196rem;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
    }
    h1 {
        font-size: 32rem;
    }
    p.text {
        font-size: 16rem;
    }
    .block-ai {
        padding: 36rem 36rem 32rem;
        height: 100%!important;
        margin: 0 auto;
        position: relative;
        display: flex;
        justify-content: flex-end!important;
        &.wrapper {
            .block {
                margin: 0 auto;
                padding: 0;
                background: transparent;
                border-radius: 0;
                height: 100%;
                width: 424rem;
            }
            .block-animate {
                padding: 0;
                height: 100%!important;
                > div {
                    height: 100%;
                }
     
    
            }
            .block-height {
                height: 100%;
            }
            .block-button {
                padding: 0;
            }
        }
        &::after {
            position: absolute;
            content: '';
            width: 100%;
            height: 50vh;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 26.88%);   
            z-index: 0;
        }
        .css-mqftaq {
            height: 100%;
            position: relative;
            z-index: 1;
        }
    }
    .wrapper.block-white .block-animate {
        padding: 0;
    }

    .brandname-wrapper {
        max-width: 464rem;
    }
}
@media screen and (min-width: 1500px)  {
    html {
        font-size: 1px;
    }
}