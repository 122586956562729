.genarate {
    &-loader {
        &-wrapper {
            grid-gap: 12rem;
            display: flex;
            flex-direction: column;
            margin-bottom: 32rem;
        }
        &-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12rem;
            span{
                color: #000;
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 14rem;
                font-style: normal;
                font-weight: 700;
                line-height: 130%;
                &:last-child {
                    font-weight: 400;
                }
                @media screen and (min-width: 500px) {
                    font-size: 16rem;
                } 
            }
        }
        &-line {
            position: relative;
            strong {
                border-radius: 100rem;
                background: #E9EEFF;
                height: 6rem;
                display: block;
            }
            span {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-radius: 100rem;
                background: var(--Blue, #2555FF);
            }
        }
    }

    &-slider {
        width: calc(100% + 40rem);
        margin: 0 -20rem;
        .swiper-slide {
            height: 0;
            display: flex;
            align-items: center;

        }
        .swiper-slide-active {
            height: 100%;
        }
        .swiper-slide-prev {
            height: 100%;
        }
        .swiper-wrapper {
            align-items: center;
        }

    }
}