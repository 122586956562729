.myInput {
    width: 100%;
    height: 49rem;
    border: none;
    box-shadow: none;
    border: 1rem solid var(--bg-gray, #F2F4F6);
    border-radius: 11rem;
    font-weight: 400;
    font-size: 14rem;
    line-height: 130%;
    color: #000000;
    outline: none;
    padding-right: 16rem;
    padding-left: 45rem;
    position: relative;
    z-index: 1;
    @media screen and (min-width: 500px) {
        font-size: 16rem;
    } 
    &::placeholder {
        color: #94969A;
    }

    &[type='password'] {
        font: small-caption;
        font-size: 16rem;

        &::placeholder {
            font-weight: 400;
            font-size: 16rem;
            line-height: 130%;
            font-family: "Italian Plate No2 Expanded";
        }
    }
}

.searchContainer {
    position: relative;
    z-index: 0;
    &.active {
        .myInput {
            border: 1rem solid #EAEAEA;
        }

        .arrow {
            display: none;
        }
    }

    .arrow {
        position: absolute;
        right: 16rem;
        top: 12rem;
        cursor: pointer;
    }

    .search {
        position: absolute;
        left: 16rem;
        top: 12rem;
        pointer-events: none;
        z-index: 1;
    }

    svg {}

    .optionsWrapper {
        position: relative;

        &:after {
            position: absolute;
            width: 100%;
            content: '';
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 1.05%, #FFF 11.78%);
            height: 118rem;
            top: -98rem;
            left: 0;
             z-index: 0;
             pointer-events: none;
        }
        &:before {
            position: absolute;
            width: 100%;
            content: '';
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 1.05%, #FFF 11.78%);
            height: 118rem;
            bottom: -98rem;
            left: 0;
             z-index: 1;
             pointer-events: none;
        }
    }
    ul {
        position: relative;
        // top: 47rem;
        // left: 0;
        background: #fff;
        width: 100%;
        // height:  calc(var(--vh, 1vh) * 100 - 36rem - 32rem - 16rem - 16rem - 62rem - 12rem - 49rem - 62rem - 42rem);
        height: calc(var(--vh, 1vh) * 100 - 31rem - 16rem - 62rem - 36rem - 32rem - 62rem - 24rem - 49rem - 16rem);
        
        overflow-y: auto;
        border-left: none;
        padding-top: 24rem;
        padding-bottom: 32rem;
        &.bname {
            height: calc(var(--vh, 1vh) * 100 - 16rem - 16rem - 36rem - 36rem - 32rem - 36rem - 31rem - 24rem - 50rem - 62rem);
        }

       

        label {
            padding: 0 16rem;
        }

        @media screen and (min-width: 500px) {
            // height:  calc(var(--vh, 1vh) * 100 - 36rem - 32rem - 16rem - 16rem - 62rem - 12rem - 49rem - 62rem - 42rem - 32rem);
            
            height: calc(var(--vh, 1vh) * 100 - 64rem - 50rem - 32rem - 36rem - 38rem - 24rem - 49rem - 62rem - 14rem);
        }

        &.countryList {
            padding-top: 12rem;
            height: calc(var(--vh, 1vh) * 100 - 62rem - 16rem - 62rem - 36rem - 32rem - 62rem - 24rem - 49rem - 16rem);

            @media screen and (min-width: 500px) {
                height: calc(var(--vh, 1vh) * 100 - 64rem - 50rem - 32rem - 36rem - 76rem - 24rem - 49rem - 62rem - 14rem);
            }

            b {
                font-weight: 600 !important;
            }

            span {
                font-weight: 400;
                display: block;
            }

        }

        &::-webkit-scrollbar {
            width: 4rem;
            right: 4rem;
            position: absolute;
            top: 0;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: #B8B8B8;
            border-radius: 12rem;
        }

    }

    li {
        height: 47rem;
        border: 1rem solid #EAEAEA;
        border-top: none;
        border-right: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12rem;
        font-weight: 500;

        line-height: 130%;
        color: #000000;
        box-sizing: border-box;
        width: 100%;

        span {
            font-weight: 400;
            font-size: 16rem;
            line-height: 130%;
            color: #94969A;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

@media screen and (min-width: 500rem) {
    .myInput {
        font-size: 17rem;
        line-height: 130%;

        &[type='password'] {
            font-size: 17rem;

            &::placeholder {
                font-weight: 400;
                font-size: 17rem;
                line-height: 130%;
                font-family: "Italian Plate No2 Expanded";
            }
        }
    }

    .searchContainer {
        margin-bottom: 20rem;

        ul {
            position: static;
        }
    }
}