.featured {
    &-block {
        border-radius: 20rem;
        background: #5BF0A5;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 20rem 13rem 20rem;
        margin-bottom: 24rem;
        > svg {
            margin-bottom: 16rem;
        }
        &__title {
            color: #000;
            text-align: center;
            font-family: 'Merriweather', serif;
            font-size: 18rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 20rem;
        }
    }
    &-bottom {
        padding-bottom: 33rem;
        p {
            margin-bottom: 21rem;
            text-align: center;
        }
        &-images {
            max-width: 267rem;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}