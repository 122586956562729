.review {
    border-radius: 12rem;
border: 1rem solid var(--Light-Gray, #F2F4F6);
padding: 16rem 20rem;
    &-top {
        display: flex;
        align-items: center;
        margin-bottom: 10rem;
    }
    &__ava {
        width: 32rem;
        height: 32rem;
        margin-right: 10rem;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    &__name {
        color: #000;
        font-family: Italian Plate No2 Expanded, sans-serif;
        font-size: 14rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 5rem;
        @media screen and (min-width: 500px) {
            font-size: 16rem;
        } 
    }
    &-info {
        &-bottom {
            display: flex;
            align-items: center;
            svg {
                width: 51rem;
                height: 10rem;
                margin-right: 6rem;
            }
            span {
                color: var(--Dark-Gray, #8F969C);
                font-family: Italian Plate No2 Expanded, sans-serif;
                font-size: 10rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @media screen and (min-width: 500px) {
                    font-size: 12rem;
                } 
            }
        }
    }
    .text {
        color: #000;
        font-size: 12rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        @media screen and (min-width: 500px) {
            font-size: 14rem;
        } 
    }
}