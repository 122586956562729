.works {
    &__steps {
        max-width: 250rem;
        &-title {
            color: #000;
            font-family: Italian Plate No2 Expanded;
            font-size: 16rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            margin-bottom: 24rem;
        }
    }

    &__step {
        display: flex;
        margin-bottom: 30rem;
        position: relative;
        &:last-child {
            margin-bottom: 0;
        }
   

        &-icon {
            width: 44rem;
            min-width: 44rem;
            height: 44rem;
            margin-right: 16rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: #E9EEFF;
            position: relative;
            svg {
                width: 16rem;
                height: 16rem;
            }

            &--cancel {
                background: #2555FF;
            }
        }
        &-line {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            svg {
                width: 1rem;
                height: 42rem;
            }
        }
        &-title {
            color: #000;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 16rem;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            margin-bottom: 6rem;
            &--cancel {
                color: var(--Blue, #2555FF);
            }
        }
        &-text {
            color: #000;
            font-family: Italian Plate No2 Expanded, sans-serif;
            font-size: 13rem;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            strong {
                font-weight: 700;
                position: relative;
                &:after {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 4rem;
                    background: var(--Green, #5BF0A5);
                    left: 0;
                    bottom: -3rem;
                }
            }
        }
    }
}